/* FAQ section styles */
.faq-answers {
    color: #58595B;
    font-family: "Interstate", "Open Sans";
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;
    margin-top: 0
}
.faq-answer-section-alphabet {
    padding-right: 1rem;
}
.faq-answer-section-numbers {
    padding-right: 1rem;
    padding-left: 3rem;
}
.faq-answer-list-security {
    list-style-type:none;
    padding-right: 1rem;
    padding-left: 3rem;
}
.faq-answer-table {
    border-style: dotted;
    border-collapse: collapse;
}

/* Google places autocomplete search bar styles */
.pac-container { z-index: 10000 !important; }

/* Salesforce chat styles */
.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #005290 !important;
    font-family: "Arial", sans-serif !important;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #005290 !important;
}
